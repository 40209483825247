import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const TextInputContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  position: relative;
  flex-direction: column;

  @media ${device.desktop} {
    margin-bottom: 30px;
  }

  svg.error {
    @media ${device.tablet} {
    }
  }
`

export const Input = styled.input`
  display: block;
  /* outline: none; */
  width: 100%;
  border-radius: 2px;
  padding: ${props =>
    props.floatingLabel ? "23px 15px 5px 15px" : "5px 15px 5px 15px"};
  height: 60px;
  font-size: 16px;
  color: #4c4d58;
  border: none;
  outline: none;
  background: #f5f7f9;
  border-bottom: 3px solid ${props => props.theme.blue};
  font-weight: 300;

  border: ${props => props.showError && "1px solid #F7376A"};

  @media ${device.desktop} {
    height: 80px;
    padding: ${props =>
      props.floatingLabel ? "20px 20px 0px 30px" : " 5px 20px 5px 20px"};
    font-size: 20px;
  }
`

export const Area = styled(Input)`
  padding: ${props =>
    props.floatingLabel ? "23px 15px 3px 15px" : "5px 15px 5px 15px"};
  height: 120px;
  resize: none;
  @media ${device.desktop} {
    height: 180px;
    padding: ${props =>
      props.floatingLabel ? "38px 20px 20px 30px" : " 5px 20px 5px 20px"};
  }
`
