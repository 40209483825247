import React from "react"
import Layout from "../components/layout"
import { ContactPage } from "../components/ContactPage"

const IndexPage = ({ pageContext }) => {
  const { header } = pageContext

  return (
    <Layout title="Contact" headerImg={header ? header.headerImage : false}>
      <ContactPage />
    </Layout>
  )
}

export default IndexPage
