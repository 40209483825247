import styled from "styled-components"
import { device } from "utils/breakpoints.js"

export const CheckboxContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  position: relative;
  align-items: baseline;
  padding-left: 46px;
  @media ${device.desktop} {
    float: left;
    padding-top: 3px;
    width: 67%;
    margin-top: 12px;
  }
`

export const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 28px;
  width: 28px;
  background: #f5f7f9;
  transition: background 90ms;
  pointer-events: none;
  border: 2px solid #0000ff;
  border-radius: 1.5px;

  ::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transition: 100ms;
    width: 7px;
    height: 16px;
    border: solid ${props => props.theme.blue};
    border-width: 0 2px 2px 0;
    transform: translate3d(-50%, -40%, 0) scale(0.3) rotate(45deg);
  }

  /* ${CheckboxContainer}:hover & {
    background: #ccc;
  } */
`

export const Input = styled.input`
  opacity: 0;
  height: 0;
  width: 0;

  /* make input clickable region reach the entire container div */
  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  /* show checkmark pseudo when input is checked */
  :checked ~ span.checkmark {
    /* background: ${props => props.theme.primaryColor}; */

    ::after {
      opacity: 1;
      transform: translate3d(-50%, -60%, 0) scale(1) rotate(45deg);
    }
  }
`

export const Label = styled.label`
  font-weight: 300;
  font-size: 14px;
  color: #5a5a5a;
  letter-spacing: 0.44px;
  @media ${device.desktop} {
    font-size: 16px;
  }
`
