import styled from "styled-components"
import { device } from "breakpoints"
import AvenueImg from "components/AvenueImg"

export const ContactPageContainer = styled.div`
  padding-bottom: 40px;

  @media ${device.desktop} {
    padding-bottom: 110px;
  }

  h4 {
    border-top: 1px solid rgba(158, 157, 168, 0.4);
    padding-top: 35px;
    font-size: 24px;
    margin-bottom: 22px;
    color: ${props => props.theme.blue};
    @media ${device.desktop} {
      font-size: 32px;
      padding: 0;
      border: none;
      margin-bottom: 27px;
    }
  }

  p.findUs {
    font-size: 16px;
    font-weight: 300;
    color: #5a5a5a;
    font-size: 16px;
    color: #5a5a5a;
    letter-spacing: 0.44px;
    border-bottom: 1px solid rgba(158, 157, 168, 0.3);
    padding-bottom: 30px;
    @media ${device.desktop} {
      font-size: 18px;
      padding-bottom: 34px;
    }
  }
`

export const MapsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
  }

  @media ${device.desktop} {
    flex-direction: column;
  }
`
export const MapModule = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 30px 0;
  border-bottom: ${props =>
    props.first && `1px solid rgba(158, 157, 168, 0.4)`};

  @media ${device.tablet} {
    border-bottom: none;
  }

  @media ${device.desktop} {
    border-bottom: ${props =>
      props.first && `1px solid rgba(158, 157, 168, 0.4)`};
  }
`
export const MapImg = styled(AvenueImg)`
  margin-right: 10px;
  width: 140px;
  height: 140px;
  flex-shrink: 0;
`
export const MapText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding-left: 10px;
  color: #5a5a5a;
  align-items: flex-start;

  h4 {
    color: ${props => props.theme.blue};
    font-size: 16px;
    margin-bottom: 8px;
    border-top: none;
    padding-top: 5px;
  }

  p.address {
    margin-bottom: 13px;
    font-weight: 300;
    color: #5a5a5a;
    font-size: 14px;
  }

  span.name {
    font-weight: 500;
    margin-bottom: 5px;
  }

  div.number {
    position: relative;

    a,
    p {
      font-weight: 300;
      margin-left: 9px;
      color: #5a5a5a;
      display: inline;

      :before {
        display: none;
      }
    }

    span {
      font-weight: 500;
    }
  }

  a.email {
    font-weight: 600;
    margin-top: 14px;
  }

  a {
    position: relative;
    display: inline-block;
    :before {
      content: "";
      width: 100%;
      height: 2px;
      opacity: 0;
      transition: 70ms;
      background: ${props => props.theme.green};
      position: absolute;
      bottom: -1px;
      left: 0;
    }

    :hover {
      :before {
        opacity: 1;
      }
    }
  }
`
