import styled from "styled-components"
import { device } from "../../utils/breakpoints.js"

export const FormWrap = styled.form`
  width: 100%;
  max-width: 675px;
  margin: 0 auto;
`

export const Label = styled.label`
  margin-bottom: 10px;
  font-size: 16px;

  @media ${device.desktop} {
    font-size: 20px;
  }
`

export const FloatingLabel = styled(Label)`
  position: absolute;
  top: 18px;
  left: 15px;
  transition: 140ms;
  transform: ${props =>
    props.fieldActive ? "translate3d(0px, -7px, 0)" : "translate3d(0,0,0)"};
  font-size: ${props => (props.fieldActive ? "12px" : "16px")};
  font-weight: ${props => (props.fieldActive ? 500 : 300)};
  color: #5A5A5A;
  @media ${device.desktop} {
    top: 30px;
    left: 30px;
    font-size: ${props => (props.fieldActive ? "14px" : "20px")};
    transform: ${props =>
      props.fieldActive ? "translate3d(0px, -12px, 0)" : "translate3d(0,-5px,0)"};
  }
`
export const ErrorMessage = styled.p`
  color: #f7376a;
  font-weight: 600;
  font-size: 14px;
  margin-top: 7px;
  display: flex;
  margin-bottom: -7px;

  @media ${device.desktop} {
    font-size: 16px;
    margin-top: 9px;
    margin-bottom: -6px;
  }

  svg {
    width: 16px;
    height: 14px;
    margin-right: 7px;
    transform:translateY(2px);

    @media ${device.desktop} {
      transform:translateY(5px);
    
    }
  }
`
