import React, { useState } from "react"
import { TextInputContainer, Input, Area } from "./style"
import { Label, FloatingLabel, ErrorMessage } from "components/Form/style.js"

const TextInput = ({
  name,
  placeholder,
  value,
  type,
  onChange,
  className,
  error,
  showError,
  label,
  floatingLabel,
}) => {
  const [fieldActive, setFieldActive] = useState(false)

  // useEffect(() => {
  //   if (value.length) {
  //     setFieldActive(true)
  //   } else {
  //     setFieldActive(false)
  //   }
  // }, [value])

  return (
    <TextInputContainer className={className}>
      {label && <Label htmlFor={name}>{label}</Label>}

      {floatingLabel && (
        <FloatingLabel fieldActive={fieldActive} htmlFor={name}>
          {floatingLabel}
        </FloatingLabel>
      )}
      {type === "textarea" ? (
        <Area
          as="textarea"
          floatingLabel={floatingLabel}
          name={name}
          showError={showError}
          placeholder={placeholder || ""}
          value={value}
          type={type}
          fieldActive={fieldActive}
          onChange={onChange}
          id={name}
          onFocus={() => setFieldActive(true)}
          onBlur={() => !value.length && setFieldActive(false)}
        />
      ) : (
        <Input
          floatingLabel={floatingLabel}
          name={name}
          showError={showError}
          placeholder={placeholder || ""}
          value={value}
          type={type}
          fieldActive={fieldActive}
          onChange={onChange}
          id={name}
          onFocus={() => setFieldActive(true)}
          onBlur={() => !value.length && setFieldActive(false)}
        />
      )}

      {showError && <ErrorMessage>{error}</ErrorMessage>}
    </TextInputContainer>
  )
}

export default TextInput

TextInput.defaultProps = {
  type: "text",
}
