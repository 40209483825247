import styled from "styled-components"
import { Form } from "../Form"
import { device } from "utils/breakpoints.js"
import { Link } from "gatsby"

export const SubmitButton = styled(Link)`
  background: none;
  outline: none;
  border: none;
  width: auto;
  cursor: pointer;
  color: ${props => props.theme.blue};
  font-size: 16px;
  display: flex;
  margin-top: 10px;
  float: right;
  align-items: center;

  :hover {
    color: #0c0ccd !important;
  }

  @media ${device.desktop} {
    font-size: 20px;
    margin-top: 5px;
  }

  svg {
    height: 36px;
    width: 36px;
    margin-left: 15px;

    @media ${device.desktop} {
      height: 40px;
      width: 40px;
    }
  }
`

export const CustomForm = styled(Form)`
  max-width: 100%;
  width: 100%;

  > * {
    display: ${props => props.submitted && "none"};
  }
`

export const Request = styled.p`
  font-size: 18px;
  margin-bottom: 15px;
  display: block !important;
  line-height: 27px;
  color: black;
  @media ${device.desktop} {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: 150%;
  }
`

export const SubText = styled.p`
  font-size: 16px;
  margin-bottom: 36px;
  font-weight: 300;
  display: block !important;
  color: #5a5a5a;
  @media ${device.desktop} {
    font-size: 20px;
    margin-bottom: 30px;
    margin-bottom: ${props => props.submitted && "25px"};
  }
`

export const SuccessMessage = styled(Request)`
  color: #434345;
  display: ${props => (props.submitted ? "block" : "none")} !important;

  @media ${device.desktop} {
    font-size: 20px;
    margin-bottom: 40px;
  }
`
export const BackToHome = styled(SubmitButton)`
  svg {
    margin-right: 15px;
    margin-left: 0;
    transform: scale(-1);
    @media ${device.desktop} {
      margin-right: 21px;
    }
  }
  @media ${device.desktop} {
    float: left;
  }
`
