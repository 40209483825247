import React, { useState } from "react"
import { TextInput } from "../Form/TextInput"
import { Checkbox } from "../Form/Checkbox"
import { CustomForm, SubmitButton, BackToHome, Request, SubText } from "./style"
import { ReactComponent as Arrow } from "images/icons/right-arrow.svg"
import { useStaticQuery, graphql } from "gatsby"
import gql from "graphql-tag"
import { useMutation } from "@apollo/react-hooks"

const CREATE_CONTACT_ENQUIRY = gql`
  mutation CreateContactEnquiry(
    $email: String!
    $fullname: String!
    $phone: String!
    $message: String!
    $newsletterSubscriber: String!
  ) {
    createContactEnquiry(
      input: {
        clientMutationId: "CreateEnquiry"
        email: $email
        phone: $phone
        fullName: $fullname
        message: $message
        newsletterSubscriber: $newsletterSubscriber
      }
    ) {
      data
      success
    }
  }
`

const FormEg = () => {
  const [submitted, setSubmitted] = useState(false)
  const [createContactEnquiry] = useMutation(CREATE_CONTACT_ENQUIRY)

  const onSubmit = values => {
    setSubmitted(true)

    createContactEnquiry({
      variables: {
        email: values.email,
        phone: values.phone,
        fullname: values.fullname,
        message: values.message,
        newsletterSubscriber:
          values.subscribe[0] === "checked" ? "true" : "false",
      },
    })
  }

  const wpData = useStaticQuery(
    graphql`
      query {
        wpgraphql {
          voiceOptions {
            siteSettings {
              contactPrimaryText
              contactSecondaryText
            }
          }
        }
      }
    `
  )

  const textData = wpData.wpgraphql.voiceOptions.siteSettings


  return (
    <>
      <CustomForm submitted={submitted} noValidate onSubmit={onSubmit}>
        <Request>
          {submitted
            ? "Thanks for your message!"
            : textData?.contactPrimaryText}
        </Request>

        {submitted ? (
          <SubText submitted={submitted}>
            We’ll aim to get back in touch as soon as possible.
          </SubText>
        ) : (
          <SubText>{textData?.contactSecondaryText}</SubText>
        )}

        <TextInput
          name="fullname"
          error="Please enter a name "
          floatingLabel="Your name"
        />

        <TextInput
          type="email"
          name="email"
          error="Please enter a valid email address"
          floatingLabel="Email address"
        />

        <TextInput type="text" name="phone" floatingLabel="Phone" />
        <TextInput
          type="textarea"
          name="message"
          error="Please enter a message"
          floatingLabel="Your message"
        />

        <Checkbox
          name="subscribe"
          value="checked"
          label="By checking this box you are agreeing to receive email communications from Voice Brand Agency. We won’t share your details with any third parties, and we’ll only send you emails from time to time."
        />
        {submitted ? (
          <BackToHome to="/">
            <Arrow />
            Back to the homepage
          </BackToHome>
        ) : (
          <SubmitButton submit as="button" submitted={submitted}>
            Submit message <Arrow />
          </SubmitButton>
        )}
      </CustomForm>
    </>
  )
}

export default FormEg
