import React from "react"
import {
  ContactPageContainer,
  MapsContainer,
  MapModule,
  MapImg,
  MapText,
} from "./style"
import { FlexGrid, FlexRow, FlexCol } from "components/FlexGrid"
import { ContactForm } from "components/ContactForm"
import useMedia from "hooks/useMedia.js"
import { GetLocationsData } from "utils/locationsData"

const ContactPage = () => {
  const locationsData = GetLocationsData()

  const isMobile = useMedia(
    // Media queries
    ["(max-width: 767px)"],
    //options
    [true],
    // default
    false
  )

  return (
    <ContactPageContainer>
      <FlexGrid gap={[35, 40, 60]}>
        <FlexRow>
          <FlexCol xs={12} md={8}>
            <ContactForm />
          </FlexCol>
          <FlexCol xs={12} md={4}>
            <h4>Find us</h4>

            <p className="findUs">
              Voice operates across Asia-Pacific, working with clients globally.{" "}
            </p>
            <MapsContainer>
              {locationsData.map((location, index) => {
                return (
                  <MapModule key={index} first={index === 0}>
                    <MapImg fluid={location.img} />
                    <MapText>
                      <h4>{location.title}</h4>
                      <p
                        className="address"
                        dangerouslySetInnerHTML={{
                          __html: location.address,
                        }}
                      />
                      <span className="name">{location.person}</span>
                      <div className="number">
                        <span>M</span>
                        {isMobile ? (
                          <a href={`tel:${location.mobile}`}>
                            {location.mobile}
                          </a>
                        ) : (
                          <p>{location.mobile}</p>
                        )}
                      </div>

                      <div className="number">
                        <span>P</span>
                        {isMobile ? (
                          <a href={`tel:${location.telephone}`}>
                            {location.telephone}
                          </a>
                        ) : (
                          <p>{location.telephone}</p>
                        )}
                      </div>
                      <a className="email" href={`mailto:${location.email}`}>
                        Email {location.person.split(" ", 2)[0]}
                      </a>
                    </MapText>
                  </MapModule>
                )
              })}
            </MapsContainer>
          </FlexCol>
        </FlexRow>
      </FlexGrid>
    </ContactPageContainer>
  )
}

export default ContactPage
